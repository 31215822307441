@use "../custom" as *;

.filter {
  position: relative;
  z-index: 99 !important;
  margin-top: 10rem;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100vh;
    border: 1px solid rgba($white, 0.1);
    min-height: 100px;
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    padding: 0 2.6875rem;
    backdrop-filter: blur(1.5625rem);

    @media (max-width: 800px) {
      flex-direction: column;
      gap: 4.375rem;
      border-radius: 0.625rem;
      padding: 2.5rem 0;
    }

    .dropdown {
      @media (max-width: 800px) {
        width: 100% !important;
        margin: auto;
        text-align: center;
        justify-content: center;
        display: flex;
      }
      
    }

    &-sm {
      display: flex;
      gap: 30px;
      flex-direction: column;
      height: 100%;
      border-radius: 0.625rem;
      border: 1px solid rgba($white, 0.1);
      min-height: 100px;
      background: linear-gradient(
        0deg,
        rgba($white, 0.1) 0%,
        rgba($white, 0.04) 59.44%
      );
      padding: 2.6875rem;
      backdrop-filter: blur(1.5625rem);

      .dropdown {
        width: 100% !important;
        margin: auto;
        text-align: center;
        justify-content: center;
        display: flex;

        &-menu{
          left: 50% !important;
          transform: translateX(-50%) !important;
        }
      }
    }
  }
}
