@use "../custom" as *;

.select {
  &__btn {
    color: $white;
    padding: 0 3rem;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: -0.03rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;

    @media (max-width: 1070px) {
      padding: 0 1rem;
    }
  }

  &__menu {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid rgba($white, 0.1);
    background: no-repeat url("../../assets/images/filter-menu.png");
    background-size: cover;
    padding: 0;
    overflow: hidden;
    @media (max-width: 800px) {
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.75rem;
      border-bottom: 1px solid rgba($white, 0.1);
      color: rgba($white, 0.4);
      letter-spacing: -0.03rem;
      font-weight: 600;
      &:hover {
        background-color: rgba($black, 0.1) !important;
        color: $white;

        img,
        svg {
          opacity: 1;
        }

        &::before {
          display: block;
        }
      }

      &::before {
        content: "";
        width: 0.3125rem;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $danger;
        display: none;
      }

      img,
      svg {
        position: absolute;
        right: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.4;
      }
    }
  }
}
