@use "../custom" as *;

.faq{
  &__headline{
    color: $white;
    font-size: clamp(2.5rem, 5vw, 3.125rem);
    font-weight: 800;
    letter-spacing: -0.0938rem;
  }

  &__wrapper{
    margin-top: 2.5rem;
  }
}

