@use "../custom" as *;

.card {
  &__primary {
    position: relative;
    min-height: 25rem;
    border-radius: 1.25rem;
    border-radius: 20px;
    border: 1px solid rgba($white, 0.1);
    padding: 1.25rem;
    background: linear-gradient(
      0deg,
      rgba($black, 0.1) 0%,
      rgba($black, 0.04) 59.44%
    );
    backdrop-filter: blur(1.5625rem);
    height: 100%;

        &:hover{
      &::after{
        content: "";
        opacity: 0.1;
      }
    }

    &::after{
      content: "";
      border-radius: 100vh;
      width: 100%;
      height: 100%;
      background-color: $white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1.7rem;
      filter: blur(40px);
      z-index: -1;
      opacity: 0;
    }

    &-img {
      width: 100%;
      height: 12.5rem;
      background: no-repeat url("../../assets/images/product-bg.png");
      background-size: cover;
      background-position: center bottom;
      border-radius: 1rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-title,
    &-price {
      display: block;
      color: $white;
      margin: 0;
      font-weight: 700;
      margin: 1.25rem 0 0.8125rem;
    }

    &-title {
      font-size: 1rem;
      letter-spacing: -0.03rem;
    }

    &-price {
      font-size: 1.5rem;
      letter-spacing: -0.045rem;
    }

    &-description {
      color: rgba($white, 0.4);
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 1.25rem;
      letter-spacing: -0.0262rem;
    }
    &-long-description {
      color: rgba($white, 0.4);
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0;
      margin-bottom: 1.25rem;
      letter-spacing: -0.0262rem;
    }

    &-btn {
      position: relative;
      width: 100%;
      img {
        position: absolute;
        top: 50%;
        right: 0.6875rem;
        transform: translateY(-50%);
      }
    }
  }

  &__secondary {
    height: 25rem;
    padding: 1.25rem;
    border-radius: 1.25rem;
    border: 1px solid rgba($white, 0.1);
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    backdrop-filter: blur(1.5625rem);

    &-img{
      width: 100%;
      height: 20rem;
      overflow: hidden;
      border-radius: 1rem;

      img,svg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-link{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;
      margin-top: 1rem;
      span{
        color: $white;
        font-weight: 600;
        letter-spacing: -0.03rem;
      }
      img,svg{
        object-fit: contain;
        transition: all ease-in-out 200ms;
      }
      &:hover{
        img,svg{
          transform: translateX(0.5rem);
        }
      }
    }
  }
}
