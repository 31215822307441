@use "../custom" as *;

.btn {
  &-nav-close {
    opacity: 1;
    display: flex;
    align-items: center;
    gap: 1.375rem;
    span {
      color: $white;
      font-weight: 600;
    }

    svg {
      min-width: 1.875rem;
      height: 1.875rem;
    }
  }

  &-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    padding: 0.75rem 2.375rem;
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    backdrop-filter: blur(25px);
    border: 1px solid rgba($white, 0.1);
    color: $white;

    &:hover {
      background: linear-gradient(
        0deg,
        rgba($white, 0.2) 0%,
        rgba($white, 0.05) 59.44%
      );
      border: 1px solid rgba($white, 0.2);
    }
  }

  &-danger {
    color: $white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 100vh;
    padding: 1rem 1.5rem;
    height: 3.25rem;
    font-weight: 600;
    letter-spacing: -0.03rem;
    background: linear-gradient(88deg, $danger 0.81%, #c50000 94.75%);
    img,
    svg {
      transition: all ease-in-out 200ms;
    }
    &:hover {
      background: linear-gradient(88deg, $danger 0.1%, #e91515 70.75%);

      img,
      svg {
        transform: translateX(8px);
      }
    }
  }

  &-paypal {
    display: flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: -0.03rem;
    gap: 1.25rem;
    justify-content: center;
    height: 3.25rem;
    color: $white !important;
    padding:0 2rem;

    &-text {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }
    border-radius: 100vh;
    border: 0.0625rem solid $white !important;
    background: linear-gradient(88deg, $black 0.81%, #212121 94.75%);
    &:hover{
      background: linear-gradient(88deg, $black 0.81%, #3e3e3e 94.75%);
    }
  }
}
