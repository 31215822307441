//Default color
$primary: #2266e1;
$secondary: #09afef;
//Theme colors

// White and gray colors
$white: #ffffff;
$danger: #f00;

$gray: #cbcbcb;

$black: #000;

$jakarta: "Plus Jakarta Sans", sans-serif;

//Navbar
$navbar-light-icon-color: $white;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 24'><path fill='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M0.0088662 11.6019C0.044331 11.5393 0.0797958 11.4678 0.106394 11.4051C0.390113 10.7074 0.913219 10.3407 1.64911 10.278C1.72891 10.2691 1.79984 10.278 1.87963 10.278C11.9516 10.278 22.0236 10.278 32.0957 10.278C32.7606 10.278 33.3192 10.4838 33.6916 11.0563C34.0462 11.593 34.0905 12.1834 33.8068 12.7738C33.5054 13.391 32.9911 13.6862 32.3262 13.7488C32.202 13.7577 32.0868 13.7488 31.9627 13.7488C22.0414 13.7488 12.129 13.7398 2.20768 13.7577C1.15261 13.7577 0.381247 13.4536 0 12.416C0.0088662 12.1387 0.0088662 11.8703 0.0088662 11.6019Z'/><path fill='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M21.2434 3.42602C17.5639 3.42602 13.8756 3.42602 10.1961 3.42602C9.34498 3.42602 8.61795 2.79091 8.51155 1.96795C8.39629 1.08237 8.93713 0.250466 9.78829 0.0447261C9.98334 0 10.1873 0 10.3823 0C17.626 0 24.8697 0 32.1134 0C33.2571 0 33.993 0.688781 33.993 1.74432C33.9841 2.67462 33.2483 3.42602 32.335 3.43496C30.1894 3.44391 28.0438 3.43496 25.8982 3.43496C24.3466 3.42602 22.795 3.42602 21.2434 3.42602Z'/><path fill='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M24.6392 24C22.13 24 19.6209 24 17.1118 24C16.1276 24 15.4804 23.4633 15.3119 22.533C15.1435 21.5937 15.9148 20.6187 16.8547 20.574C17.1738 20.5561 17.493 20.574 17.8122 20.574C22.6354 20.574 27.4498 20.574 32.273 20.574C33.1507 20.574 33.8778 21.2001 33.9842 22.041C34.0994 22.9266 33.5763 23.7227 32.7252 23.9463C32.5124 24 32.2907 24 32.0691 24C29.5954 24 27.1128 24 24.6392 24Z'/></svg>");

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
