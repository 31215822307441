@use "../custom" as *;

.about{
  &__content{
    padding-left: clamp(1rem, 5vw, 3.75rem);
    display: grid;
    gap: 1.6875rem;
  }
  &__headline{
    color: $white;
    font-size: clamp(2.5rem, 5vw, 2.5rem);
    letter-spacing: -0.0938rem;
    margin: 0;
  }
  &__description{
    font-size: clamp(1rem, 5vw, 1.25rem);
    color: rgba($white, 0.4);
    letter-spacing: -0.0375rem;
    margin: 0;
  }
}
