@use "../custom" as *;

.reasonSwiper {
  max-width: 65.625rem;
}

.reasons {
  
  &__headline {
    color: $white;
    text-align: center;
    font-size: 2.25rem;
    letter-spacing: -0.0675rem;
    span{
      font-size: clamp(2.5rem, 5vw, 3.125rem);
      font-weight: 800;
      letter-spacing: -0.0938rem;
    }
  }

  &__content {
    position: relative;
    margin: 2.4375rem 0;
  }

  &__item {
    @include media-breakpoint-down(sm) {
      width: 15rem;
      margin: auto;
    }
    width: 100%;
    height: 15rem;
    border-radius: 1.25rem;
    border-radius: 20px;
    border: 0.0625rem solid rgba($white, 0.1);
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    backdrop-filter: blur(1.5625rem);
    padding: 1.375rem 1.7625rem 1.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all ease-in-out 200ms;

    &:hover{
      &::after{
        content: "";
        opacity: 0.8;
      }
    }

    &::after{
      content: "";
      border-radius: 100vh;
      width: 6rem;
      height: 3.75rem;
      background-color: $white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1.7rem;
      filter: blur(40px);
      opacity: 0;
    }

    &-icon {
      background: linear-gradient(
        0deg,
        rgba($black, 0.5) 0%,
        rgba($black, 0.2) 59.44%
      );
      border-radius: 100vh;
      border: 5px solid rgba($white, 0.1);
      width: 6.25rem;
      min-height: 6.25rem;
      display: grid;
      place-items: center;
      backdrop-filter: blur(1.5625rem);
      margin-bottom: 1.8125rem;

      img,
      svg {
        width: 3.125rem;
        height: 3.125rem;
      }
    }

    &-headline,
    &-tag {
      display: block;
      text-align: center;
      margin: 0;
      letter-spacing: -0.48px;
      
    }

    &-headline {
      color: $white;
      font-weight: 700;
    }

    &-tag {
      color: rgba($white, 0.4);
      font-size: 0.75rem;
      letter-spacing: -0.36px;
      
    }
  }

  &__next,
  &__prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 40px;
    height: 40px;
    border-radius: 100vh;
    border: 2px solid $white;
    display: grid;
    place-items: center;

    &:hover{
      background-color: rgba($white, 0.1);
    }

  }

  &__next {
    right: 0;
  }
  &__prev {
    left: 0;
  }
}
