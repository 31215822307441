@use "../custom" as *;

.accordion {
  display: grid;
  gap: 1.25rem;

  &-item {
    background-color: transparent !important;
    border: none !important;
  }

  &-header {
    border-radius: 100vh;
    overflow: hidden;
    border: 1px solid rgba($white, 0.1);
  }

  &-button {
    color: $white !important;
    padding: 0.75rem 3.75rem;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2.5rem;
    backdrop-filter: blur(1.5625rem);
    box-shadow: none !important;
    background: linear-gradient(
      0deg,
      rgba($white, 0.2) 0%,
      rgba($white, 0.1) 59.44%
    ) !important;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding: 0.75rem 1rem;
    }

    &::after {
      content: "";
      background: no-repeat url("../../assets/icons/right.svg") !important;
      background-size: contain !important;
      background-position: center !important;
      rotate: -90deg;
      height: 1rem;
      display: grid;
      place-items: center;
    }

    &.collapsed {
      background: linear-gradient(
        0deg,
        rgba($white, 0.1) 0%,
        rgba($white, 0.04) 59.44%
      ) !important;

      &::after {
        content: "";
        rotate: 0deg;
      }
    }
  }

  &-body {
    color: rgba($white, 0.7);
    letter-spacing: -0.0375rem;
    font-size: clamp(1rem, 5vw, 1.25rem);
    font-weight: 400;
    line-height: 2.5rem;
  }
}
