@use "../custom" as *;

.store {
  &__wrapper {
    margin: 1.875rem 0;
  }

  &__headline {
    color: $white;
    font-size: 2.25rem;
    letter-spacing: -0.0675rem;
    margin: 0;

    span {
      font-size: clamp(2.5rem, 5vw, 3.125rem);
      font-weight: 700;
      display: block;
      letter-spacing: -0.0938rem;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    color: $white;
    font-weight: 600;
    @include media-breakpoint-down(md) {
      width: max-content;
      margin: 1rem auto 0;
    }

    &:hover img,
    &:hover svg {
      transform: translateX(0.5rem);
    }

    img,
    svg {
      height: 1rem;
      transition: all ease-in-out 200ms;
    }
  }


}
