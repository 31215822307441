@use "../custom" as *;

.stats {
  &__wrapper {
    max-width: 69.375rem !important;
    margin: auto;
  }
  &__card {
    padding: 3rem 1.5625rem 1.5625rem;
    height: 15rem;
    border-radius: 1.25rem;
    position: relative;
    border-radius: 20px;
    border: 1px solid rgba($white, 0.1);
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    backdrop-filter: blur(1.5625rem);

    @include media-breakpoint-down(lg) {
      margin-top: 2rem;
    }

    &-icon {
      display: grid;
      place-items: center;
      position: absolute;
      left: 50%;
      top: -3.125rem;
      transform: translateX(-50%);
      width: 6.25rem;
      height: 6.25rem;
      border-radius: 100vh;
      background: no-repeat url("../../assets/icons/stats-bg.png");
      background-size: contain;

      img,
      svg {
        width: 60px;
        height: 3.75rem;
      }
    }

    &-count {
      color: $white;
      font-size: 3.125rem;
      font-weight: 800;
      letter-spacing: -0.0938rem;
      display: block;
      text-align: center;
      line-height: normal;
    }
    &-title {
      color: rgba($white, 0.5);
      font-size: 1.5625rem;
      font-weight: 800;
      text-align: center;
      display: block;
      letter-spacing: -0.0469rem;
      line-height: normal;
    }
    &-subtitle {
      color: rgba($white, 0.3);
      font-weight: 500;
      letter-spacing: -0.03rem;
      display: block;
      text-align: center;
      margin-top: 1.25rem;
      line-height: 1;
    }
  }
}
