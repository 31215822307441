@use "../custom" as *;

.founder {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    position: relative;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
      gap: 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      top: 12.5rem;
      right: 0;
      background: no-repeat url("../../assets/images/durovic-signature.png");
      background-size: contain;
      max-width: 57.875rem;
      width: 100%;
      height: 24.25rem;
      opacity: 0.1;
      z-index: -1;
    }
  }

  &__about {
    display: grid;
    gap: 2.25rem;
    max-width: 31.25rem;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      gap: 1rem;
      text-align: center;
    }
  }

  &__headline {
    color: $white;
    font-size: clamp(2.5rem, 5vw, 3.125rem);
    font-weight: 700;
    letter-spacing: -0.0938rem;
    margin: 0;
  }

  &__description {
    color: rgba($white, 0.4);
    font-size: clamp(1rem, 5vw, 1.25rem);
    font-weight: 400;
    letter-spacing: -0.0375rem;
    margin: 0;
    line-height: 2.5rem;
  }

  &__signature {
    margin-top: 4.3125rem;
  }

  &__title {
    color: $white;
    letter-spacing: -0.03rem;
  }

  &__img {
    max-width: 36.4375rem;
    position: relative;

    &::after {
      content: "“Grip the Wheel, Feel Speed!”";
      position: absolute;
      top: 11.4375rem;
      width: max-content;
      left: -40px;
      z-index: 0;
      padding: 1.5625rem 0.9375rem;
      border-radius: 100vh;
      color: $white;
      font-weight: 500;
      font-style: italic;
      border: 1px solid rgba($white, 0.1);
      background: linear-gradient(
        0deg,
        rgba($white, 0.1) 0%,
        rgba($white, 0.05) 59.44%
      );

      backdrop-filter: blur(0.2rem);

      @include media-breakpoint-down(lg) {
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
      }
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
    img {
      width: 100%;
    }
  }
  &__badge {
    width: 12.1875rem;
    height: 9rem;
    position: absolute;
    right: 5%;
    top: 25%;

    @media (max-width: 550px) {
      width: 140px;
      height: 89px;
      top: 20%;
      right: 0%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
