@use "../custom" as *;

.intro {
  position: relative;
  min-height: 65.375rem;

  @include media-breakpoint-down(lg) {
    height: auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45%;
    height: 100%;
    background: $black;
    background: linear-gradient(
      133deg,
      rgba($black, 1) 22.83%,
      rgba($black, 0) 70.6%
    );
    filter: blur(20px);
    pointer-events: none;
    z-index: 0;
    @include media-breakpoint-down(md) {
      opacity: 0.5;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 25%;
    background: $black;
    background: linear-gradient(
      180deg,
      rgba($black, 0) 0%,
      rgba($black, 1) 70.37%
    );
    pointer-events: none;
    z-index: -1;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .container {
    position: relative;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;

    @include media-breakpoint-down(md) {
      object-position: 80% 0%;
      height: 26.875rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 14.6875rem;
    height: 100%;
    gap: 1.25rem;

    @include media-breakpoint-down(md) {
      align-items: center;
      padding-top: 13.0625rem;
    }
  }

  &__headline,
  &__description {
    max-width: 36.875rem;
    margin: 0;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  &__headline {
    color: $white;
    font-size: clamp(2.5rem, 5vw, 5rem);
    font-weight: 800;
    letter-spacing: -0.15rem;
    text-shadow: 0px 4px 1.875rem $black;
  }

  &__description {
    font-size: clamp(0.875rem, 5vw, 1.25rem);
    color: $white;
    letter-spacing: -0.0375rem;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width: max-content;

    &:hover {
      .intro__link-icon {
        background-color: rgba($danger, 0.2);

        img,
        svg {
          transform: translateX(3px);
        }
      }
    }

    span {
      color: $white;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: -0.03rem;
    }

    &-icon {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100vh;
      border: 1px solid $danger;
      display: grid;
      place-items: center;

      img,
      svg {
        height: 1rem;
        transition: all ease-in-out 200ms;
      }
      i {
        font-size: 1rem;
      }
    }
  }

  &__km {
    pointer-events: none;
    width: 12.3125rem;
    height: 12.3125rem;
    border-radius: 100vh;
    border: 0.9375rem solid $danger;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 1.1875rem;
    background: radial-gradient(
      438.41% 438.41% at 48.22% 52.53%,
      $black 0%,
      $danger 58.85%
    );
    filter: drop-shadow(0.3125rem 0.25rem 9rem rgba($danger, 0.5));
    line-height: 1;
    box-shadow: 0 0 2.5rem 0.3125rem rgba($danger, 0.4);
    &::after {
      content: "";
      width: 13.9375rem;
      height: 13.9375rem;
      border-radius: 100vh;
      border: 1px solid $danger;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &-count {
      color: $white;
      font-size: 3rem;
      font-weight: 700;
    }
    &-name {
      color: rgba($white, 0.5);
      font-size: 1.25rem;
      font-weight: 600;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
