@use "../custom" as *;

.offcanvas {
  background-color: $black;
  @include media-breakpoint-down(lg) {
    height: 100vh;
  }

  .offcanvas-header .btn-nav-close svg {
    opacity: 0;
    transition: 200ms;
    transform: rotate(180deg) scale(0.5);
  }

  &.show {
    .offcanvas-header .btn-nav-close svg {
      animation: close-btn 0.5s forwards;

      @keyframes close-btn {
        from {
        }
        to {
          opacity: 1;
          transform: rotate(0deg) scale(1);
        }
      }
    }
  }
}

.navbar {
  background: linear-gradient(
    0deg,
    rgba($white, 0.1) 0%,
    rgba($white, 0.04) 59.44%
  );
  backdrop-filter: blur(25px);
  min-height: 6.25rem;
  padding: 0;
  &-toggler {
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &-brand {
    max-width: 12.5rem;
    img {
      width: 100%;
    }
  }

  &-nav {
    gap: 1.9375rem;
    @include media-breakpoint-down(lg) {
      justify-content: center !important;
      align-items: center;
    }
  }
}

.nav {
  &-item {
    min-height: 6.25rem;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      min-height: auto;
    }
  }

  &-link {
    font-size: 1rem;
    font-weight: 500;
    color: rgba($white, 0.6);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;

    @include media-breakpoint-down(lg) {
      font-size: 1.875rem;
      font-weight: 600;
    }

    &:hover,
    &:active,
    &:focus {
      color: rgba($white, 0.85);
    }

    &.active {
      color: $white !important;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 1.25rem;
        height: 0.25rem;
        border-radius: 100vh;
        background: $white;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }

  &-languages {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 0.3125rem;
    margin-left: clamp(1rem, 3vw, 6.25rem);

    &-item {
      font-size: 1rem;
      color: rgba($white, 0.3);
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      &:hover {
        color: rgba($white, 0.5);
      }
      &.active {
        color: $white;
      }
    }
  }
}
