@use "../custom" as *;

.references {
  padding-bottom: 100px;
  display: block;
  &__item {
    width: 6.1476rem !important;
    height: 6.1476rem !important;

    @include media-breakpoint-down(sm) {
      width: 3.75rem !important;
      height: 3.75rem !important;
    }

    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
