@use "../custom" as *;

.footer {
  padding-top: 6.6875rem;
  background: linear-gradient(
    0deg,
    rgba($white, 0.1) 0%,
    rgba($white, 0.04) 59.44%
  );
  backdrop-filter: blur(1.5625rem);

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      gap: 3rem;
    }
  }

  &__about {
    display: grid;
    gap: 1.25rem;
  }

  &__description {
    color: rgba($white, 0.4);
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
    max-width: 12.5rem;
    letter-spacing: -0.0262rem;
    line-height: 1.875rem;
  }

  &__menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6.25rem;
    &-list {
      display: grid;
      gap: 1.25rem;
      margin: 0;
      padding: 0;
    }

    &-title {
      color: $white;
      font-weight: 600;
      letter-spacing: -0.03rem;
    }

    &-item {
      font-size: 0.875rem;
      color: rgba($white, 0.4);
      font-weight: 600;
      letter-spacing: -0.03rem;

      &:hover {
        color: rgba($white, 0.5);
      }
    }

    &-sublink {
      color: $white;
      font-weight: 600;
      font-size: 0.875rem;
      margin-top: 2rem;
      display: block;
    }
  }

  &__social {
    display: grid;
    gap: 1.25rem;

    &-title {
      color: rgba($white, 0.4);
      font-weight: 600;
    }

    &-links {
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }

    &-item {
      width: 1.25rem;
      height: 1.25rem;

      svg,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      i {
        font-size: 1.25rem;
        color: $white;
      }
    }
  }

  &__contact {
    padding: 0;
    display: grid;
    gap: 0.625rem;

    &-item {
      display: flex;
      align-items: center;
      gap: 0.9375rem;
      padding: 0.625rem 0.9375rem;

      span {
        color: rgba($white, 0.4);
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: -0.0262rem;

        a {
          text-decoration: none;
          color: white;
        }
      }

      &-black {
        border-radius: 100vh;
        background: linear-gradient(
          90deg,
          rgba($white, 0.1) 0%,
          rgba($white, 0) 93.24%
        );

        span {
          color: $white;
        }
      }
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: $white;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

  &__copyright{
    padding: 1.875rem 0 3.125rem;
    border-top: 1px solid rgba($white, 0.1);

    p{
      text-align: center;
      margin: 0;
      color: rgba($white, 0.4);
      font-weight: 600;
      letter-spacing: 0.05rem;
      line-height: normal;
      
    }
  }
}
