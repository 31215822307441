@use "custom" as *;

// import commponent
@use "components/buttons";
@use "components/select";
@use "components/fonts";
@use "components/cards";
@use "components/accordions";

//import sections
@use "sections/navbar";
@use "sections/intro";
@use "sections/reasons";
@use "sections/store";
@use "sections/founder";
@use "sections/references";
@use "sections/about";
@use "sections/stats";
@use "sections/faq";
@use "sections/cars";
@use "sections/tuning";
@use "sections/filter";
@use "sections/footer";

* {
  text-decoration: none !important;
  list-style: none !important;
  font-family: $jakarta;
}

html,
body,
main {
  overflow-x: hidden;
}

body {
  background-color: $black !important;
  min-height: 100vh;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 55rem;
    left: 0;
    height: 70.625rem;
    width: 100%;
    background: no-repeat url("../assets/images/durovic-signature.png");
    background-size: cover;
    background-position: 80% 50%;
    pointer-events: none;
    z-index: -1;
    opacity: 0.07;
  }
}

main {
  min-height: 100vh;
  position: relative;

  &::before {
    left: 0;
    content: "";
    position: absolute;
    background: no-repeat url("../assets/images/home-bg-full.png");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% -35%;
    pointer-events: none;
    z-index: -1;
    opacity: 0.2;
  }
}

section {
  padding: 3.125rem 0 !important;
}

.container {
  max-width: 1224px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.vertical-divider {
  width: 0.0625rem;
  height: 1.875rem;
  background: linear-gradient(90deg, $white 0%, rgba($white, 0) 100%);
  opacity: 0.4;
}

.title__center {
  font-size: clamp(3.125rem, 5vw, 4.6875rem);
  color: $white;
  font-weight: 800;
  letter-spacing: -0.1406rem;
  text-align: center;
  line-height: 1;
  margin: 0;

  span {
    font-size: clamp(2.5rem, 5vw, 3.125rem);
    font-weight: 400;
    letter-spacing: -0.0938rem;
  }
}

.road {
  position: fixed;
  top: 26.875rem;
  z-index: 3;
  left: 1rem;
  width: max-content;

  @media (max-width: 84.375rem) {
    display: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin: 0;
    padding: 0;

    &-link {
      display: block;
      width: 1.25rem !important;
      height: 1.25rem !important;
      border-radius: 100vh;
      background-color: rgba($white, 0.15);
      cursor: pointer;

      &.active {
        background-color: $white;
      }
    }
  }
}

.swiper-wrapper {
  justify-content: space-between;
}

.footer__wrapper,
.about__content,
.intro__headline,
.intro__description,
.store__headline,
.founder__description,
.founder__signature,
.founder__title {
  text-align: left;
}

.dropdown-menu.show {
  max-height: 350px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.dropdown-menu.show::-webkit-scrollbar {
  display: none;
}

.store__box {
  width: 300px;
  height: 235px;
  background-color: transparent;
  border-radius: 4px;
  margin-top: 6rem;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: space-around;
}

@media (max-width: 767.98px) {
  .store__box {
    width: 150px;
    height: 117.5px;
    margin-top: 4rem;
    margin-left: 1rem;
  }
}
.store__box__footer {
  width: 300px;
  height: 235px;
  background-color: transparent;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: space-around;
}

@media (max-width: 767.98px) {
  .store__box__footer {
    width: 150px;
    height: 117.5px;
  }
}

.swiper.referencesSwiper::-webkit-scrollbar {
  display: none;
}

.swiper.referencesSwiper {
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.partnerLogo {
  width: -webkit-fill-available;
  height: 100px;
  object-fit: contain;
}