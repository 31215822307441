@use "../custom" as *;

.tuning {
  margin: 6.25rem 0;

  &__brand {
    max-width: 31.25rem;
    width: 100%;
    height: 30rem;
    border-radius: 1.25rem;
    border: 1px solid rgba($white, 0.1);
    padding: 2rem;
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    backdrop-filter: blur(1.5625rem);
    display: grid;
    place-items: center;

    img,
    svg {
      max-width: 22.6875rem;
      width: 100%;
      object-fit: contain;
    }
  }

  &__headline {
    color: $white;
    font-size: 2rem;
    font-weight: 800;
    line-height: 5rem;
    margin: 0;
    letter-spacing: -0.06rem;
  }

  &__description {
    color: $white;
    font-size: 1.25rem;
    letter-spacing: -0.0375rem;
    margin: 0;
  }

  &__subtitle {
    color: $white;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -0.0375rem;
    margin-top: 1rem;
    display: block;
  }

  &__bar {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 100vh;
    border: 1px solid rgba($white, 0.1);
    background: linear-gradient(
      0deg,
      rgba($white, 0.1) 0%,
      rgba($white, 0.04) 59.44%
    );
    backdrop-filter: blur(1.5625rem);
    height: 2.8125rem;
    margin-top: 0.625rem;
    position: relative;
    justify-content: start;
    overflow: hidden;
    gap: 0;

    @keyframes tuningBar {
      0% {
        transform: skew(320deg) translateX(-100%);
      }
      100% {
        transform: translateX(-12px) skew(320deg);
      }
    }
    @keyframes tuningBar2 {
      0% {
        transform: skew(320deg) translateX(-100%);
        background-color: transparent;
      }
      100% {
        transform: translateX(-12px) skew(320deg);
        background-color: $danger;
      }
    }

    &-standart {
      background-color: $white;
      height: 100%;
      transform: skew(320deg) translateX(-100%);
      position: relative;
      z-index: 2;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: tuningBar 2s forwards;
      animation: tuningBar 2s forwards;
    }

    &-stage {
      height: 100%;
      transform: skew(320deg) translateX(-100%);
      z-index: 1;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: tuningBar2 2s 1s forwards;
      animation: tuningBar2 2s 1s forwards;
    }

    &-value {
      color: $white;
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: -0.0375rem;
    }
  }

  &__data {
    margin-top: 0.625rem;
    &-item {
      color: $white;
      font-weight: 500;
      letter-spacing: -0.03rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      span {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }

  &__price {
    color: $white;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.06rem;

    span {
      font-weight: 800;
    }
  }
}
